import * as React from "react";
import { styled, css } from "@lib/components";

export const Grid = styled("div") <{ sideBySide: boolean }>`

  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  
  .col-1 {
    width: 100%;
    margin-bottom: 30px;
  }
  .col-2 {
    width: 100%;
  }
  
  ${({ sideBySide }) => (!sideBySide) ? "" : css`
    @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      flex-direction: row;
      .col-1 {
        width: 40%;
        text-align: right;
        padding-right: 100px;
      }
      .col-2 {
        width: 60%;
        text-align: left;
      }
    }  
  `};
  
`;

export const Features = styled("div")`
  width: 100%;
  max-width: 250px;
  margin: 40px auto 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 100%;
  }  
`;

export const Feature = styled("div")`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
    font-weight: 600;
  }
`;
