import { hot } from "react-hot-loader/root";
import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../mobx/component";
import { Nav } from "./sections/nav";
import { Header } from "./sections/header";
import { AboutUs } from "./sections/about_us";
import { Gallery } from "./sections/gallery";
import { Locations } from "./sections/locations";
import { Footer } from "./sections/footer";
import { CoverLoader } from "./components/CoverLoader";
import { styled } from "@lib/components";
import tokenizeJapanese from "@lib/common/dist/esm/utils/cjk-tokenizer/tokenizers/japanese";

interface Props extends WithTranslation {}

interface State {
  isShowBigMap: boolean;
}

export const Wrapper = styled("main")`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  .main-sections {
    section {
      padding: 0 0 100px 0;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          padding: 0 0 140px 0;
      }
      &:first-child {
        padding: 60px 0 100px 0;
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
          padding: 80px 0 140px 0;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
          padding: 80px 0 140px 0;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
          padding: 100px 0 140px 0;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
          padding: 100px 0 120px 0;
        }
      }
      &:last-child {
        padding-bottom: 60px;
        @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
           padding-bottom: 80px;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
           padding-bottom: 100px;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
           padding-bottom: 120px;
        }
        @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
           padding-bottom: 120px;
        }
      }
    }
  }
`;

@inject("store") @observer
class AppClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowBigMap: false,
    };
  }

  bigMapShow = () => {
    this.setState({ isShowBigMap: true });
  }

  bigMapHide = () => {
    this.setState({ isShowBigMap: false });
  }

	render() {

		const paddingTop = this.injected.store.view.s.top_nav_height;
    const { isShowBigMap } = this.state;
		return (
			<Wrapper style={{ paddingTop: paddingTop + "px" }}>
				<Nav />
				{!isShowBigMap && <Header />}
				<div className="main-sections">
					{!isShowBigMap && <AboutUs />}
					{!isShowBigMap && <Gallery />}
					<Locations showBigMap={this.bigMapShow} hideBigMap={this.bigMapHide}/>
				</div>
				{!isShowBigMap && <Footer />}
        {isShowBigMap && <CoverLoader />}
			</Wrapper>
		);
	}

}

export const App = hot(withTranslation()(AppClass));
