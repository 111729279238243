import * as React from "react";
import { Provider as ProviderComponent } from "mobx-react";
import { RootStore } from "../mobx/store";
import { App } from "./app";
import { ThemeProvider } from "@lib/components";
import { I18nextProvider } from "react-i18next";

interface Props {
	store: RootStore;
	theme: T.Core.BaseTheme.ThemeInterface;
}

export default class ApplicationContainer extends React.Component<Props> {

	render() {
		return (
			<ProviderComponent store={this.props.store}>
				<ThemeProvider theme={this.props.theme}>
					<I18nextProvider i18n={this.props.store.intl.i18n}>
						<App />
					</I18nextProvider>
				</ThemeProvider>
			</ProviderComponent>
		);
	}

}
