import { observable, action, autorun, computed } from "mobx";
import { RootStore } from "../store";
import { CoreUtils } from "@lib/common";
import throttle from "lodash/throttle";
import { logger } from "@lib/common";

export interface ViewState {
	breakpoint: T.UIBreakpoint;
	screen_width: number;
	scroll_top: number;
	top_nav_height: number;
	menu_nav_stuck: boolean;
	mobile_menu_active: boolean;
	is_web_view: boolean;
	platform: string;
	app_id: string | null;
	domain: string;
}

export class ViewStore {

	s: ViewState;

	store: RootStore;

	constructor(store: RootStore, initialState?: ViewState) {
		this.store = store;
		this.s = observable({
			breakpoint: "md" as T.UIBreakpoint,
			screen_width: 720,
			scroll_top: 0,
			top_nav_height: 70,
			menu_nav_stuck: false,
			mobile_menu_active: false,
			is_web_view: false,
			platform: "web",
			app_id: "",
			domain: "",
			...(initialState || {}),
		});
		if (typeof window !== "undefined") {
			this.windowResize();
			this.windowScroll();
			window.addEventListener("resize", throttle(this.windowResize, 100));
			document.addEventListener("scroll", throttle(this.windowScroll, 50));
		}
	}

	@action windowResize = () => {
		const width = window.innerWidth;
		const breakpoint = CoreUtils.ui.breakpoint(width);
		this.update({
			screen_width: width,
			breakpoint: breakpoint,
		});
	}

	@action windowScroll = () => {
		try {
			const h1 = window.pageYOffset;
			const h2 = document.documentElement ? document.documentElement.scrollTop : 0;
			const h3 = document.body.scrollTop;
			this.s.scroll_top = Math.max(h1, h2, h3);
		}
		catch (e) {
			logger.captureException(e, "SCROLL FUNCTION ERROR");
		}
	}

	@action update = (data: Partial<ViewState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof ViewState];
				if (value !== undefined && this.s) {
					// @ts-ignore
					this.s[key as keyof ViewState] = value;
				}
			}
		}
	}

}
