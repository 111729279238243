import * as React from "react";
import Measure, { ContentRect } from "react-measure";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import {
	Content,
	Logo,
	LogoImage,
	LogoText,
	MenuDesktop,
	MenuLink,
	MenuMobile,
	MenuMobileContent,
	Wrapper,
} from "./components";
import * as qs from "querystring";
import { CoreUtils } from "@lib/common";
import { Button } from "@lib/components";
import { FaBars } from "react-icons/fa";
import { Collapse } from "react-collapse";
import { computed } from "mobx";

interface Props { }

const STORE_LOCATIONS_SECTION = "store-locations-section";

interface MenuLinkType {
	_id: string;
	hide: boolean;
	type: string;
	name: string;
	href?: string;
	oc?: (e: any) => void;
}

@inject("store") @observer
export class Nav extends MobxComponent<Props> {

	@computed get links(): MenuLinkType[] {

		const { view, website } = this.injected.store;
		const { hide_order_button, links } = website.sections.top_nav;
		const s = website.sections;

		const isSingleLocation = website.store_locations.length === 1;

		const showMobileMenu = view.s.screen_width < 920;

		const defaultLinkData: MenuLinkType[] = [
			{
				_id: "about_us",
				hide: s.about_us.hidden || (s.about_us.text.length === 0 && s.about_us.features.length === 0),
				type: "link",
				name: "About Us",
				href: undefined,
				oc: () => this.scrollTo("about-us-section", showMobileMenu ? 40 : 30),
			},
			{
				_id: "location",
				hide: false,
				type: "link",
				name: isSingleLocation ? "Location" : "Locations",
				href: undefined,
				oc: () => this.scrollTo(STORE_LOCATIONS_SECTION, showMobileMenu ? 120 : 140),
			},
			{
				_id: "contact_us",
				hide: s.contact_form.hidden || !s.contact_form.email,
				type: "link",
				name: "Contact Us",
				href: undefined,
				oc: () => this.scrollTo("contact-us-section", showMobileMenu ? 120 : 130),
			},
			{
				_id: "order",
				hide: hide_order_button,
				type: "button",
				name: "Order Online",
				href: undefined,
				oc: (e: any) => this.orderOnlineButtonClick(e),
			},
		];

		let finalLinks = defaultLinkData;
		if (links) {
			finalLinks = links.filter((l) => !!l.name).map((link) => {
				const ld = defaultLinkData.find((l) => l._id === link._id);
				if (ld) {
					return { ...ld, name: link.name };
				}
				return {
					...link,
					hide: false,
					type: "link",
					href: link.link,
				};
			});
		}

		return finalLinks;

	}

	componentDidMount(): void {
		this.forceUpdate();
	}

	onComponentResize = (contentRect: ContentRect) => {
		if (contentRect.bounds) {
			this.injected.store.view.update({
				top_nav_height: contentRect.bounds.height,
			});
		}
	}

	scrollTo = (el: string, offset: number) => {
		const element = document.getElementById(el);
		if (element) {
			const bodyRect = document.body.getBoundingClientRect().top;
			const elementRect = element.getBoundingClientRect().top;
			const elementPosition = elementRect - bodyRect;
			const offsetPosition = elementPosition - offset;
			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth",
			});
			this.injected.store.view.update({
				mobile_menu_active: false,
			});
		}
	}

	scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}

	orderOnlineButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		const { store } = this.injected;
		const { website } = store;
		const { is_web_view, app_id } = store.view.s;
		const isSingleLocation = website.store_locations.length === 1;
		if (isSingleLocation) {
			e.preventDefault();

			if (is_web_view) {
				store.loader.update({
					active: true,
					opacity: 0.75,
					title: "Loading Store Page...",
					message: null,
				});
			}

			const l = website.store_locations[0];
			let store_url = l.store_url;
			if (is_web_view) {
				const origin = window.location.origin; // `${_config.isTest ? "http" : "https"}://${domain}`;
				const store_url_query = qs.stringify({
					reseller_portal_url: !app_id ? origin : `${origin}/?app_id=${app_id}&is_web_view=TRUE`,
					is_web_view: "TRUE",
				});
				store_url = `${l.store_url}?${store_url_query}`;
			}
			window.location.href = store_url;
		}
		else {
			this.scrollTo(STORE_LOCATIONS_SECTION, 130);
		}
	}

	renderLinks = () => {

		const { store } = this.injected;

		const { hidden } = store.website.sections.top_nav;

		if (hidden) {
			return null;
		}

		const links = this.links;

		if (links.length === 0) {
			return null;
		}

		return (
			<>
				{links.map((l, i) => {
					if (l.hide)
						return null;
					if (l.type === "link") {
						return (
							<MenuLink
								key={i}
								href={l.href}
								onClick={l.oc}>
								{l.name}
							</MenuLink>
						);
					}
					return (
						<Button
							key={i}
							color="primary-inverse"
							onClick={l.oc}>
							{l.name}
						</Button>
					);
				})}
			</>
		);

	}

	render() {

		const { store } = this.injected;

		const { website } = store;

		const view = store.view;

		const {
			image_logo,
			image_logo_text,
			image_logo_url,
			image_logo_center,
			hide_nav_menu,
			hidden,
		} = website.sections.top_nav;

		if (hidden)
			return null;

		const contentStyle = image_logo_center ? { justifyContent: "center" } : { justifyContent: "space-between" };

		const image = CoreUtils.image.uc(image_logo, { format: "auto" });

		const hideMenu = hide_nav_menu || this.links.length === 0;

		return (
			<Wrapper id="top-navigation">

				<Measure bounds={true} onResize={this.onComponentResize}>
					{({ measureRef }) => (
						<Content style={contentStyle} ref={measureRef}>

							{image_logo_url && (
								<a href={image_logo_url}>
									<Logo>
										{image && <LogoImage src={image} />}
										{image_logo_text && <LogoText>{image_logo_text}</LogoText>}
									</Logo>
								</a>
							)}

							{!image_logo_url && (
								<Logo onClick={this.scrollTop}>
									{image && <LogoImage src={image} />}
									{image_logo_text && <LogoText>{image_logo_text}</LogoText>}
								</Logo>
							)}

							{!hideMenu &&
								<MenuDesktop className="hide-below-lg">
									{this.renderLinks()}
								</MenuDesktop>
							}

							{!hideMenu &&
								<Button
									className="hide-above-lg"
									color="primary-inverse"
									onClick={() => {
										console.log("CLICK");
										store.view.update({ mobile_menu_active: !view.s.mobile_menu_active });
									}}>
									<FaBars />
								</Button>
							}

						</Content>
					)}
				</Measure>

				{!hideMenu &&
					<Collapse isOpened={view.s.mobile_menu_active}>
						<MenuMobile className="hide-above-lg">
							<MenuMobileContent>
								{this.renderLinks()}
							</MenuMobileContent>
						</MenuMobile>
					</Collapse>
				}

			</Wrapper>
		);

	}

}
