import { styled, css } from "@lib/components";
import { shadeAlternate } from "@lib/components";

interface WrapperProps {
	background: string;
	color: string;
	image?: string;
	tint: string;
	contactEnabled: boolean;
}

// margin-top: ${({contactEnabled}) => contactEnabled ? 260 : 80}px;

export const Wrapper = styled("footer") <WrapperProps>`
  position: relative;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 60px 20px 60px 20px;
  margin-top: ${({ contactEnabled }) => contactEnabled ? 220 : 80}px;
  
  ${({ image, tint }) => (image && !tint) && css`
    background: url(${image}) no-repeat center center;
    background-size: cover;
  `};
  
  ${({ image, tint }) => (image && tint) && css`
    background: linear-gradient(${tint}, ${tint}), url(${image}) no-repeat center center;
    background-size: cover;
  `};
  
`;

export const Content = styled("div")`
  text-align: center;
`;

export const Divider = styled("div")`
  width: 100%;
  max-width: 300px;
  margin: 30px auto;
  height: 1px;
  background: ${({ theme }) => shadeAlternate(0.8, theme.colors.text)};
`;

export const SocialIcons = styled("div")`
  text-align: center;
  font-size: 26px;
  > * {
    margin: 0 10px;
    &:first-child { margin-left: 0 }
    &:last-child { margin-right: 0 }
  }
`;
