import * as React from "react";
import cn from "classnames";
import { logger } from "@lib/common";

interface Props {
	id: string;
	style?: React.CSSProperties;
	className?: string;
	zoom?: number;
	lat: number;
	lng: number;
	markerColor: string;
	type?: "interactive" | "static";
}

interface State {
	mapBoxUrl?: string;
}

export class MapEmbed extends React.Component<Props, State> {

	mb?: mapboxgl.Map;
	maps_timeout?: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			mapBoxUrl: ''
		}
	}

	componentDidMount() {
		this.draw();
		const script = document.getElementById("script-mapbox");
		if (script) {
			script.onload = this.draw;
		}
		this.setState({ mapBoxUrl: this.getStaticImageURL() });
	}

	componentDidUpdate(prevProps: Props) {
		const { lat, lng } = this.props;
		if (prevProps.lat !== lat || prevProps.lng !== lng) {
			this.draw();
		}
	}

	getStaticImageURL() {
		const { lat, lng, zoom, id } = this.props;
		const el = document.getElementById(id);
		if (!el) return;
		let url = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+555555(${lng},${lat})/${lng},${lat},${zoom||14},0/${el.clientWidth}x${el.clientHeight}?access_token=${mapboxgl.accessToken}`;
		
		return url;
	}

	draw() {
		const { lat, lng, zoom, id, markerColor, type } = this.props;
		if (typeof type === "undefined" || type === "interactive") {
			if (typeof window === "object" && typeof mapboxgl !== "undefined") {
				clearTimeout(this.maps_timeout);
				this.maps_timeout = setTimeout(() => {

					try {

						const el = document.getElementById(id);

						if (!el) return;

						this.mb = new mapboxgl.Map({
							container: el,
							style: "mapbox://styles/mapbox/streets-v11",
							center: [lng, lat],
							zoom: zoom || 12,
						});

						new mapboxgl.Marker({ color: markerColor })
							.setLngLat([lng, lat])
							.addTo(this.mb);

					}
					catch (e) {
						logger.captureException(e);
					}

				}, 200);
			}
		}
	}

	render() {
		return (
			<div
				id={this.props.id}
				style={this.props.style || {}}
				className={cn("google-map", this.props.className)}
			>
				{
					this.props.type === "static" && 
					typeof mapboxgl !== "undefined" &&
					<img
						src={this.state.mapBoxUrl}
						alt="Store Map"
					/>
				}
				
			</div>
		);
	}

}
