import * as React from "react";
import { observer, inject } from "mobx-react";
import { HeaderWrapper, HeaderContent } from "./components";
import { CoreUtils } from "@lib/common";
import { MobxComponent } from "../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }

@inject("store") @observer
class HeaderClass extends MobxComponent<Props> {

	render() {

		const w = this.injected.store.website;

		const {
			image_background,
			image_background_tint,
			text,
			subtitle,
			color_background,
			color_text,
			hidden,
		} = w.sections.header;

		if (hidden) return null;

		const backgroundImage = CoreUtils.image.uc(image_background, { format: "auto" });

		return (
			<HeaderWrapper
				color={color_text}
				background={color_background}
				tint={image_background_tint}
				image={backgroundImage}>
				<HeaderContent>
					{text !== " " && <h1 className="lhp">{text}</h1>}
					{subtitle && <p className="lhp big font-semi-bold m-t-2">{subtitle}</p>}
				</HeaderContent>
			</HeaderWrapper>
		);
	}

}

export const Header = withTranslation()(HeaderClass);
