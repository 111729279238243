import * as React from "react";
import { styled, css } from "@lib/components";

export const Location = styled("div")`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 3px solid ${({ theme }) => theme.box.background};
  color: ${({ theme }) => theme.box.text};
  background: ${({ theme }) => theme.box.background};
  border-radius: 3px;
  > .map {
    width: 100%;
    height: 200px;
  }
`;

export const Info = styled("div")`
  color: ${({ theme }) => theme.box.text};
  background: ${({ theme }) => theme.box.background};
  width: 100%;
  padding: 20px 25px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 25px 40px;
  }  
`;

export const Hours = styled("div")`
  background: ${({ theme }) => theme.box.background};
  overflow: hidden;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0px;
  transition: 0.3s width,padding ease-in-out;
  &.active {
      overflow-y: auto;
      width: 100%;
      > .content {
          opacity: 1;
      }
  }
`;

export const HoursContent = styled("div")`
  transition: 0.1s opacity ease-out;
  opacity: 0;
  padding: 30px 25px;
  overflow-y: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      padding: 30px 40px;
  }
`;
