console.log("INIT");
require("./init");
import * as React from "react";
import * as ReactDOM from "react-dom";
import { logger } from "@lib/common";
import { RootStore } from "../common/mobx/store";
import App from "../common/ui/index";
import { Untrusive } from "@lib/common";
import { lighten } from "polished";

console.log("INITIAL STATE");
const preloadedTheme = window.__INITIAL_THEME__ || {};
const preloadedState = window.__INITIAL_STATE__ || {};
const store = new RootStore(preloadedState);

Untrusive.init({
	barColor: lighten(0.2, preloadedTheme.colors.primary),
	bgColor: preloadedTheme.colors.primary,
});

console.log("RENDER");

const root = document.getElementById("root");

ReactDOM.hydrate(<App store={store} theme={preloadedTheme} />, root);

console.log("RENDERED");
