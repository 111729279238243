import * as React from "react";
import { styled, css } from "@lib/components";
import { Input } from "@lib/components";
import { Textarea } from "@lib/components";

export const FormWrapper = styled("div")`
  max-width: ${({ theme }) => theme.content_width.md + 100}px;
  width: 100%;
  margin: -260px auto 80px auto;
  color: ${({ theme }) => theme.box.text};
`;

export const FormContent = styled("div")`
  color: ${({ theme }) => theme.box.text};
  background: ${({ theme }) => theme.box.background};
  padding: 40px;
  z-index: 1;
`;

export const Form = styled("form")`
  
`;

export const InputField = styled(Input)`
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 52px;
  line-height: normal;
  border-radius: 3px;
  padding: 0 15px 0 45px;
`;

export const InputTextarea = styled(Textarea)`
  border-radius: 3px;
  padding: 20px;
  min-height: 200px;
`;

export const InputWrapper = styled("div")`
  position: relative;
  input {
    
  }
`;

export const InputIcon = styled("div")`
  position: absolute;
  left: 18px;
  top: 19px;
  bottom: 0;
`;
