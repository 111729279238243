import * as React from "react";
import { observer, inject } from "mobx-react";
import { Grid, Feature, Features } from "./components";
import { MobxComponent } from "../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { StrikeUnderline } from "../../components/StrikeUnderline";
import { FaCheckCircle } from "react-icons/fa";
import { Section, SectionContent } from "../../components/section";

interface Props extends WithTranslation { }

@inject("store") @observer
class AboutUsClass extends MobxComponent<Props> {

	feature = (text: string, i: number) => {
		return (
			<Feature key={i} className="col">
				<FaCheckCircle />
				<p>{text}</p>
			</Feature>
		);
	}

	render() {

		const w = this.injected.store.website;

		const {
			text,
			features,
			side_by_side,
			hidden,
		} = w.sections.about_us;
		if (hidden || (text.trim().length === 0 && features.length === 0)) return null;

		return (
			<Section id="about-us-section">
				<SectionContent>

					<Grid sideBySide={side_by_side}>

						<div className="col-1">
							<StrikeUnderline />
							<h2>About Us</h2>
						</div>

						<div className="col-2">
							<p className="big lhp">{text}</p>
							{features.length > 0 && (
								<Features className="grid-3-2 sm-2 lg-3 sm-gap">
									{features.map((t, i) => this.feature(t, i))}
								</Features>
							)}
						</div>

					</Grid>

				</SectionContent>
			</Section>
		);
	}

}

export const AboutUs = withTranslation()(AboutUsClass);
