import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { MobxComponent } from '../../../mobx/component';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  FaRegClock,
  FaMapMarkerAlt,
  FaPhone,
  FaTimesCircle,
} from 'react-icons/fa';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Section, SectionContent } from '../../components/section';
import * as qs from 'querystring';
import cn from 'classnames';
import { MapEmbed } from '../../components/map_embed';
import { RestaurantOpeningHours } from '@lib/components';
import { Location, Info, Hours, HoursContent } from './components';
import { Button } from '@lib/components';
import { StrikeUnderline } from '../../components/StrikeUnderline';
import styled, { withTheme } from 'styled-components';
import { CoreUtils } from '@lib/common';

const MapWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border: 3px solid ${({ theme }) => theme.box.background};
  color: ${({ theme }) => theme.box.text};
  background: ${({ theme }) => theme.box.background};
  border-radius: 3px;
  > .map {
    width: 100%;
    height: 100vh;
  }
`;

const CloseButtonWrapper = styled('div')`
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 80px;
`;

const CloseButton = styled('button')`
  display: flex;
  flex-direction: column;
  border: none;
`;
interface Props extends WithTranslation {
	showBigMap: () => void;
	hideBigMap: () => void;
}
interface State {
  visible_opening_hours: string[];
  isShowBigMap: boolean;
  mapProps: {
    id: string;
    lat: number | undefined;
    lng: number | undefined;
  };
}

@inject('store')
@observer
class AboutUsClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const w = this.injected.store.website;
    const { store_locations } = w;
    this.state = {
      visible_opening_hours: [],
      isShowBigMap: false,
      mapProps: {
        id: '',
        lat: undefined,
        lng: undefined,
      },
    };
  }

  toggleOpeningHours = (id: string) => {
    const index = this.state.visible_opening_hours.indexOf(id);
    const arr = [...this.state.visible_opening_hours];
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(id);
    }
    this.setState({ visible_opening_hours: arr });
  };

  openBigMap = (l: any) => {
    this.setState({
      mapProps: {
        id: l._id,
        lat: l.map_data.lat,
        lng: l.map_data.lng,
      },
      isShowBigMap: true,
    });
  };

  onLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, link: string) => {
    e.preventDefault();

    const { store } = this.injected;

    const { is_web_view } = store.view.s;
    if (is_web_view) {
      this.injected.store.loader.update({
        active: true,
        opacity: 0.75,
        title: 'Loading Store Page...',
        message: null,
      });
    }

    window.location.href = link;
  };

  getImage(locations: T.Schema.Website.WebsiteSchema['sections']['locations']) {
    let image;
    if (typeof locations?.image === 'string') {
      image = locations.image;
    } else {
      image = CoreUtils.image.uc(locations?.image, {
        resize: 'x350',
        format: 'auto',
      });
    }

    return image;
  }

  location = (
    l: T.Schema.Website.WebsiteStoreLocation,
    locations: T.Schema.Website.WebsiteSchema['sections']['locations'],
    map_embed_type: 'interactive' | 'static' | undefined,
  ) => {
    let store_url = l.store_url;
    const { store, theme } = this.injected;
    const { is_web_view, app_id } = store.view.s;
    const section = store.website.sections.locations;

    if (is_web_view && typeof window !== 'undefined') {
      const origin = window.location.origin; // `${_config.isTest ? "http" : "https"}://${domain}`;

      const store_url_query = qs.stringify({
        reseller_portal_url: !app_id
          ? origin
          : `${origin}/?app_id=${app_id}&is_web_view=TRUE`,
        is_web_view: 'TRUE',
      });

      store_url = `${l.store_url}?${store_url_query}`;
    }

    const isOpeningHoursActive =
      this.state.visible_opening_hours.indexOf(l._id) !== -1;

    
    return (
      <div key={l._id} className="col">
        <Location>
          {(!locations?.card_type || locations.card_type == 'map') &&
            l.map_data.type !== 'custom' && (
              <MapEmbed
                id={`${l._id}-map-embed`}
                className="map"
                zoom={section ? section.map_zoom : undefined}
                lat={l.map_data.lat}
                lng={l.map_data.lng}
                markerColor={theme.colors.primary}
                type={map_embed_type}
              />
            )}

          {locations && locations.card_type === 'image' && (
            <>
              <img src={this.getImage(locations)} />
            </>
          )}

          <Info>
            <div className="flex-l-r-center m-b-4">
              <h3 className="flex-start">{l.name}</h3>

              <div className="flex-end">
                <Button
                  className="m-r-2"
                  size="xs"
                  color="white"
                  onClick={() => {
										this.openBigMap(l),
										this.props.showBigMap()
									}}
                >
                  <FaMapMarkerAlt className="m-r-1" /> Map
                </Button>
                <Button
                  size="xs"
                  color="white"
                  onClick={() => this.toggleOpeningHours(l._id)}
                >
                  <FaRegClock className="m-r-1" /> Hours
                </Button>
              </div>
            </div>

            <div className="flex-line m-b-3">
              <span
                className="inline-block max24 width100"
                style={{ paddingTop: '3px' }}
              >
                <FaMapMarkerAlt />
              </span>
              <p className="lhp">{l.address}</p>
            </div>

            {!!l.phone && (
              <div className="flex-line">
                <span
                  className="inline-block max24 width100"
                  style={{ paddingTop: '3px' }}
                >
                  <FaPhone />
                </span>
                {!is_web_view && (
                  <p className="lhp">
                    <a href={`tel:${l.phone.replace(/ /g, '')}`}>{l.phone}</a>
                  </p>
                )}
                {is_web_view && <p className="lhp">{l.phone}</p>}
              </div>
            )}

            <a href={store_url} onClick={e => this.onLinkClick(e, store_url)}>
              <Button color="primary-inverse" className="m-t-3">
                View Menu & Order
              </Button>
            </a>
          </Info>

          <Hours className={cn({ active: isOpeningHoursActive })}>
            <HoursContent className="content">
              <div className="flex-l-r-center">
                <div />
                <Button
                  display="flex"
                  size="xs"
                  color="white"
                  onClick={() => this.toggleOpeningHours(l._id)}
                >
                  <FaTimesCircle className="m-r-1" /> Close
                </Button>
              </div>

              <div className="m-t-6">
                <RestaurantOpeningHours hours={l.opening_hours} />
              </div>
            </HoursContent>
          </Hours>
        </Location>
      </div>
    );
  };

  render() {
    if (!this.state) return null;
    const w = this.injected.store.website;
		const { theme } = this.injected;
    const { store_locations } = w;
    const { isShowBigMap, mapProps } = this.state;
		const section = w.sections.locations;
    const map_embed_type = store_locations.length > 15 ? 'static' : section?.map_type;
    return (
      <>
        {!isShowBigMap && (
          <Section id="store-locations-section">
            <SectionContent size="xl">
              <div className="text-heading text-center m-b-12">
                <StrikeUnderline />
                <h2>
                  {store_locations.length > 1
                    ? 'Our Locations'
                    : 'Our Location'}
                </h2>
              </div>
              <div id="store-locations" className="grid-2 lg md-gap">
                {store_locations.map(l =>
                  this.location(l, section, map_embed_type)
                )}
                {store_locations.length === 3 && <div className="col" />}
                {store_locations.length === 5 && <div className="col" />}
                {store_locations.length === 7 && <div className="col" />}
              </div>
            </SectionContent>
          </Section>
        )}

        {isShowBigMap && (
          <MapWrapper>
            <CloseButtonWrapper>
              <CloseButton onClick={() => {
								this.setState({isShowBigMap: false}),
								this.props.hideBigMap()
							}}>
                <AiFillCloseCircle size={20} />
              </CloseButton>
            </CloseButtonWrapper>
            <MapEmbed
              id={`new-${mapProps.id}-map-embed`}
              className="map"
							zoom={section ? section.map_zoom : undefined}
              lat={mapProps.lat || 0}
              lng={mapProps.lng || 0}
							markerColor={theme.colors.primary}
            />
          </MapWrapper>
        )}
      </>
    );
  }
}

// @ts-ignore
export const Locations = withTheme(withTranslation()(AboutUsClass));
