import axios from "axios";
import autobind from "autobind-decorator";
import { config } from "../../config";
import { logger } from "@lib/common";
import { RootStore } from "./store";

@autobind
export class APIStore {

	store: RootStore;

	constructor(store: RootStore) {
		this.store = store;
	}

	async request(args: T.API.RequestArgs): Promise<any> {
		const { public_key } = this.store.website;
		const { path, data, params, headers } = args;
		try {
			const res = await axios({
				baseURL: config.api_url + "/",
				url: path,
				method: "post",
				headers: { "Authorization-Site": public_key, ...(headers || {}) },
				params: params || {},
				data: data || {},
			});
			return res.data;
		}
		catch (e) {
			if (e.response && e.response.data) {
				if (e.response.data.message === "Invalid Authentication") {
					logger.warn("INVALID SERVER PUBLIC KEY");
				}
				throw e.response.data;
			}
			else {
				throw e;
			}
		}
	}

	async contact_form(data: T.API.StoresRestaurantContactFormRequest): Promise<T.API.StoresRestaurantContactFormResponse> {
		return this.request({
			path: "/website/client/contact-form",
			data: data,
		});
	}

}
