import * as React from "react";
import { RootStore } from "./store";
import i18n from "i18next";
import { observer } from "mobx-react";

export interface InjectedProps {
	theme: T.Core.BaseTheme.ThemeInterface;
	store: RootStore;
	t: i18n.TFunction;
}

@observer
export class MobxComponent<Props = {}, State = {}> extends React.Component<Props, State> {

	get injected() {
		return this.props as InjectedProps & Props;
	}

}
