const isProduction = process.env.NODE_ENV === "production";
const isStaging = process.env.STAGING === "true";
const isTest = !isProduction && !isStaging;

export const config = {
	environment: process.env.NODE_ENV || "development",
	isTest: isTest,
	isProduction: isProduction,
	isStaging: isStaging,
	api_url: isProduction ? process.env.API_HOST || "https://api.cloudwaitress.com" : "http://localhost:3010",
	cdn_url: isProduction ? process.env.SITES_CDN_URL || 'https://sites-cdn.cloudwaitress.com' : "/",
	api_public_key: "c2285f76-cc39-4ade-9269-023192e1798b",
	google_api_key: "AIzaSyAc_6JJnBdHbKjXGeoKIznTE22-YaRRuSA",
	mapbox_api_key: "pk.eyJ1IjoicmVhbGZyZXNoIiwiYSI6ImNqb2hkeTdzbTBvczMzcXBqeGE0MXlwbjkifQ.L_3vQEe6bef12JJyt6268w",
};
