import * as React from "react";
import { styled, css } from "@lib/components";
import { CS } from "@lib/components";
import { shadeAlternate } from "@lib/components";

export const Wrapper = styled("nav")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.top_nav.zindex};
  background: ${({ theme }) => theme.top_nav.background_color};
  color: ${({ theme }) => theme.top_nav.text_color};
  ${CS.shadow.light.two};
`;

export const Content = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.content_width.xl}px;
  margin: 0 auto;
  padding: 16px 20px;
  > * {
    margin: 0 10px;
    &:first-child {
      margin-left: 0
    }
    &:last-child {
      margin-right: 0
    }
  }
`;

export const Logo = styled("div")`
  cursor: pointer;
  display: flex;
  align-items: center;
  > * {
    &:nth-child(2) {
      margin-left: 16px;
    }
  }
`;

export const LogoImage = styled("img")`
  max-width: 100%;
  height: auto;
`;

export const LogoText = styled("h3")`
  font-weight: 600;
  font-size: 24px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 30px;
  }  
`;

export const MenuLink = styled("a")`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
`;

export const MenuDesktop = styled("div")`
  display: none;
  > * {
    margin-left: 20px;
  }
`;

export const MenuMobile = styled("div")`
  
`;

export const MenuMobileContent = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => shadeAlternate(0.1, theme.top_nav.background_color)};
  a {
    text-align: center
  }
  > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
