import * as React from "react";
import { styled } from "@lib/components";

export const StrikeUnderline = styled("div")`
  display: inline-block;
  width: 80px;
  height: 5px;
  margin-bottom: 20px; 
  background: ${({ theme }) => theme.colors.primary};
`;
