import * as React from "react";
import { observer, inject } from "mobx-react";
import { FormWrapper, FormContent, Form, InputIcon, InputWrapper, InputField, InputTextarea } from "./components";
import { MobxComponent } from "../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { FaUser, FaEnvelope, FaPhone } from "react-icons/fa";
import { StrikeUnderline } from "../../components/StrikeUnderline";
import { logger } from "@lib/common";
import { Button } from "@lib/components";

interface Props extends WithTranslation { }
interface State {
	name: string;
	email: string;
	phone: string;
	message: string;
	loading: boolean;
	form_message: string;
}

@inject("store") @observer
class ContactUsClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			phone: "",
			message: "",
			loading: false,
			form_message: "",
		};
	}

	onChange = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		const update = { [name]: value } as { [P in T]: State[P]; };
		this.setState(update);
	}

	submit = async (e: React.FormEvent<HTMLFormElement>) => {
		try {

			e.preventDefault();

			const {
				name,
				email,
				phone,
				message,
			} = this.state;

			const data = {
				name: name,
				email: email,
				phone: phone,
				message: message,
			};

			this.setState({ loading: true, form_message: "" });

			await this.injected.store.api.contact_form(data);

			this.setState({
				name: "",
				email: "",
				phone: "",
				message: "",
				loading: false,
				form_message: "Success! Your message has been sent",
			});

		}
		catch (e) {
			logger.captureException(e);
			this.setState({
				loading: false,
				form_message: "Something went wrong, please wait a bit and try again",
			});
		}
	}

	render() {

		if (!this.state) return null;

		const w = this.injected.store.website;

		const { email, hidden } = w.sections.contact_form;
		const { form_message, loading } = this.state;

		if (!email || hidden) return null;

		return (
			<FormWrapper id="contact-us-section">
				<div className="text-center m-b-8">
					<StrikeUnderline />
					<h2>Contact Us</h2>
				</div>
				<FormContent>
					<Form id="contact-us-form" className="" onSubmit={this.submit}>

						<div className="grid-3 md md-gap">
							<InputWrapper className="col">
								<InputIcon>
									<FaUser className="contact-input-icon" />
								</InputIcon>
								<InputField
									name="name"
									value={this.state.name}
									type="text"
									placeholder="Name"
									className="contact-input-field"
									required={true}
									onChange={this.onChange}
								/>
							</InputWrapper>
							<InputWrapper className="col">
								<InputIcon>
									<FaEnvelope className="contact-input-icon" />
								</InputIcon>
								<InputField
									name="email"
									value={this.state.email}
									type="email"
									placeholder="E-Mail"
									className="contact-input-field"
									required={true}
									onChange={this.onChange}
								/>
							</InputWrapper>
							<InputWrapper className="col">
								<InputIcon>
									<FaPhone className="contact-input-icon" />
								</InputIcon>
								<InputField
									name="phone"
									value={this.state.phone}
									type="text"
									placeholder="Phone"
									className="contact-input-field"
									required={false}
									onChange={this.onChange}
								/>
							</InputWrapper>
						</div>

						<InputTextarea
							name="message"
							value={this.state.message}
							className="m-t-6 contact-input-field"
							placeholder="Message..."
							required={true}
							onChange={this.onChange}
						/>

						<div className="text-center m-t-6"><p>{form_message}</p></div>

						<div className="text-center m-t-6">
							<Button size="md" color="primary" type="submit">
								{loading ? "Sending..." : "Send Message"}
							</Button>
						</div>

					</Form>
				</FormContent>
			</FormWrapper>
		);
	}

}

export const ContactUs = withTranslation()(ContactUsClass);
