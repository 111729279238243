import * as React from "react";
import { styled } from "@lib/components";

export const Section = styled("section")`
  
`;

export const SectionContent = styled("div") <{ size?: T.UIBreakpoint }>`
  padding: 0 20px;
  width: 100%;
  max-width: ${({ theme, size }) => theme.content_width[size || "lg"]}px;
  margin: 0 auto;
`;
