import smoothscroll from "smoothscroll-polyfill";
import * as Sentry from "@sentry/browser";
import { config } from "../config";
import { logger } from "@lib/common";

try {

	console.log("SET SENTRY");

	Sentry.init({
		enabled: config.isProduction,
		environment: config.isTest ? "development" : "production",
		dsn: "https://e8dae19b0ef74c5e93bfadd412bb1bcf@sentry.io/1384542",
		integrations: [
			new Sentry.Integrations.Breadcrumbs(),
			// new Sentry.Integrations.InboundFilters(),
			new Sentry.Integrations.TryCatch(),
			new Sentry.Integrations.UserAgent(),
		],
		attachStacktrace: true,
		ignoreErrors: [
			// Random plugins/extensions
			"top.GLOBALS",
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
			"originalCreateNotification",
			"canvas.contentDocument",
			"MyApp_RemoveAllHighlights",
			"http://tt.epicplay.com",
			"Can\"t find variable: ZiteReader",
			"jigsaw is not defined",
			"ComboSearch is not defined",
			"http://loading.retry.widdit.com/",
			"atomicFindClose",
			// Facebook borked
			"fb_xd_fragment",
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
			// reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268
			"bmi_SafeAddOnload",
			"EBCallBackMessageReceived",
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			"conduitPage",
		],
		blacklistUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		],
	});

	mapboxgl.accessToken = config.mapbox_api_key;

	window.activeModals = window.activeModals || [];

	// if (process.env.NODE_ENV === "production")
	// 	localStorage.debug = "INFO,WARN,ERROR";
	// else
	// 	localStorage.debug = "INFO,WARN,ERROR,DEV";

	smoothscroll.polyfill();

	console.log("INIT COMPLETE");

}
catch (e) {
	console.log(e);
	logger.captureException(e);
}

declare global {
	interface Window {
		__INITIAL_THEME__: T.Core.BaseTheme.ThemeInterface;
		__INITIAL_STATE__: any;
		activeModals?: string[];
	}
}