import * as React from "react";
import { observer, inject } from "mobx-react";
import { FaFacebookSquare, FaInstagram, FaTwitterSquare, FaPinterestSquare } from "react-icons/fa";
import { LinkTag, TiktokIcon } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Wrapper, Content, SocialIcons, Divider } from "./components";
import { ContactUs } from "../contact_us";

interface Props extends WithTranslation { }

@inject("store") @observer
class FooterClass extends MobxComponent<Props> {

	render() {

		const { reseller } = this.injected.store;

		const w = this.injected.store.website;

		const {
			color_background,
			color_text,
			image_background_tint,
			image_background,
			terms_text,
			terms_url,
			hidden,
		} = w.sections.footer;

		if (hidden) return null;

		const isWebView = this.injected.store.view.s.is_web_view;

		const contactEnabled = (!!w.sections.contact_form.email && !w.sections.contact_form.hidden);

		const termsActive = !!terms_text;

		const social_links = w.sections.footer.social_links || {};

		const oneSocialLinkActive =
			!!social_links.facebook ||
			!!social_links.instagram ||
			!!social_links.twitter ||
			!!social_links.pinterest ||
			!!social_links.tiktok;

		let powered_by_style = {};
		if (reseller.stores.powered_by_color) {
			powered_by_style = {
				color: `${reseller.stores.powered_by_color} !important`,
			};
		}

		return (
			<Wrapper
				color={color_text}
				background={color_background}
				tint={image_background_tint}
				image={CoreUtils.image.uc(image_background, { format: "auto" })}
				contactEnabled={contactEnabled}>

				{contactEnabled && <ContactUs />}

				<Content>

					{(oneSocialLinkActive && !isWebView) && (
						<SocialIcons>
							{social_links.facebook && (
								<a href={social_links.facebook} target="_blank"><FaFacebookSquare /></a>
							)}
							{social_links.instagram && (
								<a href={social_links.instagram} target="_blank"><FaInstagram /></a>
							)}
							{social_links.twitter && (
								<a href={social_links.twitter} target="_blank"><FaTwitterSquare /></a>
							)}
							{social_links.tiktok && (
								<a href={social_links.tiktok} target="_blank"><TiktokIcon /></a>
							)}
							{social_links.pinterest && (
								<a href={social_links.pinterest} target="_blank"><FaPinterestSquare /></a>
							)}
						</SocialIcons>
					)}

					{(oneSocialLinkActive && !isWebView) && <Divider />}

					{(!isWebView || termsActive) && (
						<div>
							<p>
								{!isWebView && (
									<span>Powered by <a style={powered_by_style} href={reseller.stores.powered_by_link} target="_blank" className="underline">{reseller.stores.powered_by_name}</a></span>
								)}
								{(!isWebView && termsActive) && <span> | </span>}
								{terms_text && (
									<span>
										{terms_url && <LinkTag href={terms_url}>{terms_text}</LinkTag>}
										{!terms_url && terms_text}
									</span>
								)}
							</p>
						</div>
					)}

				</Content>

			</Wrapper>
		);
	}

}

export const Footer = withTranslation()(FooterClass);
