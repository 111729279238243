import { toJS } from "mobx";
import { ViewState, ViewStore } from "./state/view";
import { RouterState, RouterStore } from "@lib/router";
import { ModalState, ModalStore } from "./state/modal";
import { APIStore } from "./api";
import stringify from "fast-safe-stringify";
import { LoaderState, LoaderStore } from "./state/loader";
import { IntlStore } from "@lib/intl";

interface InitialStates {
	reseller: T.Schema.Reseller.ResellerSchema;
	website: T.Schema.Website.WebsiteSchema;
	view?: ViewState;
	modal?: ModalState;
	loader?: LoaderState;
	router?: RouterState;
}

export class RootStore {

	api: APIStore;

	// STATIC
	reseller: T.Schema.Reseller.ResellerSchema;
	website: T.Schema.Website.WebsiteSchema;

	// DYNAMIC
	intl: IntlStore;
	view: ViewStore;
	modal: ModalStore;
	loader: LoaderStore;
	router: RouterStore;

	constructor(initialStates: InitialStates) {

		this.reseller = initialStates.reseller;
		this.website = initialStates.website;

		// TODO - INTL SETTINGS FOR WEBSITES
		this.intl = new IntlStore({
			useReactModule: true,
			initialState: {
				// tz: r.settings.region.timezone,
				// locale: r.settings.region.locale,
				// formats: r.settings.region.formats,
				// lng: r.settings.region.locale.split("-")[0],
				// currency: {
				//   ...r.settings.region.currency,
				//  step: CoreUtils.currency.precision_to_step(r.settings.region.currency.precision),
				// },
			},
		});

		this.router = new RouterStore(initialStates.router);
		this.view = new ViewStore(this, initialStates.view);
		this.modal = new ModalStore(this, initialStates.modal);
		this.loader = new LoaderStore(this, initialStates.loader);

		this.api = new APIStore(this);

	}

	getRootState = () => {
		return {
			reseller: this.reseller,
			website: this.website,
			view: toJS(this.view.s),
			modal: toJS(this.modal.s),
			loader: toJS(this.loader.s),
			router: toJS(this.router.s),
		};
	}

	serialize = (): string => {
		return stringify(this.getRootState());
	}

}
