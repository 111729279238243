import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { CoreUtils } from "@lib/common";
import { Section, SectionContent } from "../../components/section";
import { styled } from "@lib/components";

interface Props extends WithTranslation { }

export const GridCol = styled("div") <{ url: string }>`
  height: 275px;
  background: url(${({ url }) => url}) no-repeat center center;
  background-size: cover;  
  border-radius: 3px;
`;

@inject("store") @observer
class GalleryClass extends MobxComponent<Props> {

	render() {

		const w = this.injected.store.website;

		const { images, hidden } = w.sections.gallery;

		if (hidden)
			return null;

		return (
			<Section id="gallery-section">
				<SectionContent className="grid-3-2 md-2 lg-3 md-gap">
					{images.map((image, i) => (
						<GridCol
							key={i}
							className="col"
							url={CoreUtils.image.uc(image, { format: "auto", resize: "x275" })!}
						/>
					))}
				</SectionContent>
			</Section>
		);
	}

}

export const Gallery = withTranslation()(GalleryClass);
